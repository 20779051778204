.homeButton {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 16px;
  left: 16px;
  border-radius: 4px;
  background-color: #3f3f3f;
}

.lt-symbol {
  margin-top: 4px;
  margin-left: 4px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 20px solid white; /*this will make the symbol white*/
  background-color: transparent; /*this will make the symbol hollow*/
}