.scoreboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

table {
  max-width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #101010;
  color: #d0d0d0;
  cursor: pointer;
}

th, td {
  text-align: left;
  padding: 8px;
  width: 10px;
}

tr:nth-child(even) {
  background-color: #3f3f3f;
}