.scoreboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

table {
  max-width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #101010;
  color: #d0d0d0;
  cursor: pointer;
}

th, td {
  text-align: left;
  padding: 8px;
  width: 10px;
}

tr:nth-child(even) {
  background-color: #3f3f3f;
}

tr:nth-child(even).winnerRow {
  background-color: goldenrod;
}

.winnerRow {
  color: black;
  background-color: darkgoldenrod;
}

.winnerRow > td > span {
  color: #d0d0d0;
}

.scrollBarDiv {
  overflow-x: auto;
  height: 84vh;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #1c1c1c #3f3f3f; /* Scroll thumb and track color for Firefox */
}

.scrollBarDiv::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scrollBarDiv::-webkit-scrollbar-track {
  background: #3f3f3f; /* color of the tracking area */
}

.scrollBarDiv::-webkit-scrollbar-thumb {
  background-color: #3f3f3f; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #1c1c1c; /* creates padding around scroll thumb */
}

.profileViewBtn {
  display: block;
  width: max-content;
  background-color: #101010;
  padding: 5px;
  border-radius: 8px;
}