.historyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.character-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.character-options div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.character-options button {
  margin: 5px 0;
}

.menu {
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  gap: 8px 4px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #363636;
  color: #d0d0d0;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.colorsMenu {
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: fixed;
  gap: 8px 4px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #363636;
  color: #d0d0d0;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.menuItem {
  box-sizing: border-box;
  height: 40px;
  flex: 0 0 30%;
  background-color: #333;
  border-radius: 8px;
  text-align: center;
  align-content: center;
  transition: background-color 0.3s ease;
}

.menuColorItem {
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  text-align: center;
  align-content: center;
  transition: background-color 0.3s ease;
}

.active {
  background-color: #3f3f3f;
}

.menuActions {
  height: 40px;
  flex: 0 0 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.menuActions span {
  flex: 0 0 40%;
  text-align: center;
  align-content: center;
}

.customizationRow{
  display: flex;
  width: 310px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-top: 4px;
}

.customizationRow .label{
  width: 45px;
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 30px;
  width: 60px;
  border-radius: 8px;
}

.setSkinButtonsRow{
  display: flex;
  gap: 8px;
}

.copySkinCodeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 30px;
  width: 120px;
  border-radius: 8px;
  background-color: #3f3f3f;
}

.skinActionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 30px;
  width: 80px;
  border-radius: 8px;
  background-color: #3f3f3f;
}

.menuClosed {
  transform: translateY(100%);
}

.menuOpen {
  transform: translateY(0);
}