.simulatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.battleContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
}

.battleContainer div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}