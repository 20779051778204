.homePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.homePageContainer h1{
  margin: 0;
}

.centered{
  text-align: center;
}

.bigMenuItem{
  box-sizing: border-box;
  height: 100px;
  padding: 12px;
  background-color: #333;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  align-content: center;
  transition: background-color 0.3s ease;
  margin-bottom: 16px;
}

.bigMenuItem h2{
  margin: 0;
}

.bigMenuItem h4{
  margin: 0;
}

.banner{
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.blueRow {
  background-color: #13C3FF;
}

.banner img {
  max-width: 100%;
}