.bigError{
  box-sizing: border-box;
  padding: 12px;
  width: 300px;
  text-align: center;
  align-content: center;
  transition: background-color 0.3s ease;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  background: darkred;
  border-radius: 12px;
}

.bigError > h2, h4 {
  margin: 4px;
}