.accordion {
  box-sizing: border-box;
  padding: 12px 24px;
  height: auto;
  background-color: #333;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  align-content: center;
  transition: background-color 0.3s ease, max-height 0.3s ease-in-out;
  margin-bottom: 16px;
  max-height: max-content;
  overflow: hidden;
}

.accordion h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.accordion h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  margin: 0;
}

.accordion-arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #d0d0d0;
  transition: transform 0.3s ease-in-out; /* Add transition for smooth rotation */
}

.rotate {
  transform: rotate(180deg); /* Rotate arrow 180 degrees when .rotate class is added */
}

.accordion-body {
  display: grid;
  grid-template-rows: 0fr;
  transition: 250ms grid-template-rows ease;
}

/* accordion-body becomes active when isOpen is true */
.accordion-body.active {
  grid-template-rows: 1fr;
}

.accordion-body > div {
  overflow: hidden;
}